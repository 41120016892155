import { useEffect, useState } from 'react';

const useVisualViewport = () => {
  const [viewport, setViewport] = useState({
    height: Infinity,
    width: Infinity,
  });

  const updateViewport = () => {
    setViewport({
      height: window.visualViewport.height,
      width: window.visualViewport.width,
    });
  };

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      typeof window.visualViewport !== 'undefined'
    ) {
      updateViewport();

      window.visualViewport.addEventListener('resize', updateViewport);

      return () =>
        window.visualViewport.removeEventListener('resize', updateViewport);
    }
  }, []);
  return viewport;
};

export default useVisualViewport;
