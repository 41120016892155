import React from 'react';
import { graphql } from 'gatsby';
import Seo from '@containers/Seo';
import checkFeatureFlag from '@utils/checkFeatureFlag';
import ClaimV1 from './Claim-v1';
import ClaimV2 from './Claim-v2';

const Claim = checkFeatureFlag('MODULES') === 'FLAG_VALID' ? ClaimV2 : ClaimV1;

export const query = graphql`
  query ClaimPageV2 {
    claimPageRecallSymbol: allAirtable(
      filter: {
        table: { eq: "Game Elements" }
        data: { Name: { eq: "Recall Header" } }
      }
    ) {
      ...SvgGetFragment
      ...GatsbyImageGetFragmentNoPlaceholder
    }
    claimPageBackgroundSymbol: allAirtable(
      filter: {
        table: { eq: "Game Elements" }
        data: { Name: { eq: "Claim Background Image" } }
      }
    ) {
      ...SvgGetFragment
      ...GatsbyImageGetFragmentNoPlaceholder
    }
  }
`;

export const Head = () => <Seo />;

export default Claim;
