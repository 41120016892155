import React from 'react';
import AnimatedIntroImageWrapper from '@components/AnimatedIntroImageWrapper';
import { Display, Flex, Para, Box } from 'workspace-core-ui';
import CustomMdxRenderer from '@containers/CustomMdxRenderer';
import useSound from '@hooks/useSound';
import ControlCenter, {
  ControlCenterProps,
} from '@components/controls/ControlCenter';
import { motion } from 'framer-motion';
import { FADE_IN_CONTAINER, FADE_IN_ITEM } from '@sharedConstants';

export interface ClaimViewProps extends ControlCenterProps {
  headerText: string;
  claimTextMdx: string;
  headerSymbol: {
    imageType: 'gatsbyImage' | 'svg';
    imageData: object;
  };
}

const ClaimView = ({
  headerText,
  claimTextMdx,
  headerSymbol,
  /** callback that is triggered once our control is interacted with */
  setSubmittedAnswer,
  typeOfControl,
  possibleAnswers,
  appearance,
}: ClaimViewProps) => {
  const { playSound } = useSound();
  return (
    <motion.div
      style={{ display: 'contents' }}
      variants={FADE_IN_CONTAINER}
      initial="hidden"
      animate="show"
    >
      <Flex flex={0.5} />
      <Flex
        flexDirection="column"
        flex={0.5}
        alignItems="center"
        minWidth="50vmax"
        maxWidth="1100px"
        alignSelf="center"
      >
        {headerSymbol && (
          <Flex alignSelf="center">
            <AnimatedIntroImageWrapper
              overrideImageStyling={{
                maxWidth: 'clamp(100px, 40vw - 1rem, 400px)',
                //width: '50%',
              }}
              imageData={headerSymbol.data}
              imageType={headerSymbol.type}
            />
          </Flex>
        )}
        <Box p={5} width="100%">
          <Display
            as={motion.h2}
            variants={FADE_IN_ITEM}
            variant={appearance === 'tertiary' ? 'claimRecallTitle' : 'claimTitle'}
            mb={2}
            data-testid="claim-heading"
          >
            {headerText}
          </Display>
          <Display
            as={motion.div}
            variants={FADE_IN_ITEM}
            variant={appearance === 'tertiary' ? 'claimRecallBody' : 'claimBody'}
            maxWidth="1100px"
          >
            <CustomMdxRenderer>{claimTextMdx}</CustomMdxRenderer>
          </Display>
        </Box>
      </Flex>
      {/* NOTE: for now, assume our answers will always be in the binary true false */}
      <Flex placeContent="flex-start" flex="0.3 1 auto">
        {typeOfControl && (
          <ControlCenter
            animationVariants={FADE_IN_ITEM}
            setSubmittedAnswer={e => {
              playSound('Button');
              setSubmittedAnswer(e);
            }}
            typeOfControl={typeOfControl}
            possibleAnswers={possibleAnswers}
            appearance={appearance}
          />
        )}
      </Flex>
    </motion.div>
  );
};

export default ClaimView;
