import React, { useRef, useState, useCallback } from 'react';
import useTranslation from '@hooks/useTranslation';
import CustomMdxRenderer from '@containers/CustomMdxRenderer';
import css from '@styled-system/css';
import {
  Display,
  Button,
  Box,
  IconButton,
  Label,
  Para,
  Flex,
  Heading,
} from 'workspace-core-ui';
import { useElementScroll, motion, useTransform } from 'framer-motion';
import styled from 'styled-components';
import normalize from '@utils/normalize';
import { Link } from 'gatsby';
import { useAppDispatch } from '@hooks/redux-hooks';
import { logItem } from '@slices/loggingSlice';
import { FADE_IN_CONTAINER, FADE_IN_ITEM } from '@sharedConstants';
import getSizedParaVariant from '@utils/getSizedParaVariant';
import SubmitBetterInfoModalButton from './SubmitBetterInfoModalButton';
import ShareModalButton from './ShareModalButton';

const truncate = (str: string, n: number) =>
  str.length > n ? `${str.substr(0, n - 1)}...` : str;

const NextButton = styled(Button).attrs({
  forwardedAs: Link,
})(
  css({
    textDecoration: 'none',
  }),
);

export interface ResultsViewProps {
  // for navigation to next page
  to: string;
  heading: string;
  blurb: string;
  readMore?: string;
  goToNextScreen: () => void;
  sources?: string;
  /** for logging purposes */
  url: string;
  questionName: string;
}
const ReadMoreWrapper = styled(Para).attrs({
  variant: 'p2',
  as: 'div',
})(
  css({
    textAlign: 'center',
    overflow: 'auto',
    position: 'relative',
    height: 'min(400px, 60vh)',
    top: '20%',
    padding: 'inherit',
    pb: 4,
    '@media (max-width: 600px)': {
      height: 'min(300px, 50vh)',
    },
  }),
);

const ReadMoreView = ({
  text,
  sources,
  questionName,
}: {
  sources?: string;
  text: string;
  questionName: string;
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const { scrollYProgress } = useElementScroll(ref);
  // changes based on your scroll progress
  const opacity = useTransform(
    scrollYProgress,
    latest => normalize(1, 0)(latest) * 5,
  );

  return (
    <Box
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ReadMoreWrapper ref={ref}>
        {text && (
          <Para as="span" variant="p1">
            <CustomMdxRenderer>{text}</CustomMdxRenderer>
          </Para>
        )}
        {sources && (
          <>
            <Heading mb={3} mt={6} fontSize="14px">
              {t('Result Why True Body Header')}
            </Heading>
            <Para as="span" variant="p3">
              <CustomMdxRenderer>{sources}</CustomMdxRenderer>
            </Para>
          </>
        )}
        <SubmitBetterInfoModalButton questionName={questionName} />
      </ReadMoreWrapper>
    </Box>
  );
};

// TODO: clicking the text itself does not trigger the child onclick
const WhyTag = styled.span({
  cursor: 'pointer',
});

const whyTagAnimVariants = {
  open: { rotate: 0 },
  close: { rotate: 180 },
};

// what you are shown after you have answered either correctly or incorrectly
const ResultsView = ({
  heading,
  blurb,
  readMore,
  sources,
  goToNextScreen,
  url,
  to,
  questionName,
}: ResultsViewProps) => {
  const { t, g } = useTranslation();
  const dispatch = useAppDispatch();
  const [showReadMore, setShowReadMore] = useState<boolean>(false);

  const CustomShareButton = useCallback(
    props => (
      <Button
        variant="simple"
        sx={{ isolation: 'isolate' }}
        buttonSize="auto"
        {...props}
      >
        <Para px={3} py={2} variant="p3">
          {t('Share Modal Header')}
        </Para>
      </Button>
    ),
    [t],
  );

  return (
    <motion.div
      style={{ display: 'contents' }}
      animate="show"
      initial="hidden"
      variants={FADE_IN_CONTAINER}
    >
      <Flex flex={1} />
      <Flex
        flexDirection="column"
        flex="1 1 auto"
        maxWidth="1100px"
        alignSelf="center"
        justifyContent='space-evenly'
      >
        {!showReadMore && (
          <motion.div variants={FADE_IN_ITEM}>
            {heading && (
              // padding is for safari text bounding issue
              <Display
                as={motion.h2}
                variants={FADE_IN_ITEM}
                id="results-heading"
                mb={2}
                pt={3}
                variant="claimTitle"
              >
                {heading}
              </Display>
            )}
            <motion.div
              style={{
                touchAction: 'none',
              }}
              onPan={(e, pointInfo) => {
                // If you swiped down a bit, show the readmore tray up
                // TODO: delta value was a guess, refine it later based on user testing
                if (pointInfo.delta.y < -4) {
                  setShowReadMore(true);
                }
              }}
            >
              <Para
                as={motion.div}
                variants={FADE_IN_ITEM}
                variant={getSizedParaVariant(g(blurb), 'claimBody')}
                sx={{
                  // I do this to override the default mdx styles for images that may exist in the tray
                  // this is pretty custom but it fits with the art direction of the tray contents
                  '.gatsby-resp-image-wrapper': {
                    width: '300px',
                    m: 3,
                  },
                }}
              >
                <CustomMdxRenderer>{g(blurb, true)}</CustomMdxRenderer>
              </Para>
            </motion.div>
          </motion.div>
        )}
        <Label
          as={motion.label}
          variants={FADE_IN_ITEM}
          variant="readMore"
          pb={3}
          pt={[6, 6, 8]}
        >
          {!showReadMore && (
            <WhyTag as={motion.label} variants={FADE_IN_ITEM}>
              {t('Result Why True')}
            </WhyTag>
          )}
          <motion.div
            animate={showReadMore ? 'open' : 'closed'}
            initial={{
              rotate: 180,
            }}
            transition={{
              delay: 0.05,
            }}
            variants={whyTagAnimVariants}
          >
            {/* clicking this opens read more */}
            <IconButton
              data-testid="read-more"
              onClick={() => {
                dispatch(
                  logItem({
                    question_name: questionName,
                    collection_name: 'events',
                    event_type: 'click',
                    target: `read_more_${showReadMore ? 'close' : 'open'}`,
                  }),
                );
                setShowReadMore(e => !e);
              }}
              iconName="chevron-up"
              showOutline={false}
            />
          </motion.div>
        </Label>
        {showReadMore && (
          <ReadMoreView
            questionName={questionName}
            url={url}
            text={readMore}
            setShowReadMore={setShowReadMore}
            sources={sources}
          />
        )}
        <ShareModalButton
          loggingTarget="share_modal_button_refutation"
          modalTitle={t('Share Modal Header')}
          showVerboseNotification={false}
          textToShare={
            `${truncate(g(blurb, false), 200)} \n` +
            `: ${window?.location?.origin}`
          }
          showModalInCenter
          CustomButton={CustomShareButton}
        />
      </Flex>
      <Flex
        variants={FADE_IN_ITEM}
        as={motion.div}
        flex={0.5}
        justifyContent="flex-start"
        flexDirection="column"
        width="max-content"
        alignSelf="center"
      >
        <NextButton
          to={to}
          replace
          // TODO: use only cy
          data-testid="next-button"
          data-cy="results-next-button"
          onClick={goToNextScreen}
        >
          <Label variant="l1">{t('Next Question Button')}</Label>
        </NextButton>
      </Flex>
    </motion.div>
  );
};

export default ResultsView;
